
import axios from "axios";
import { useSelector } from "react-redux";

axios.defaults.withCredentials = true;

let access_token = localStorage.getItem('auth-token');

window.addEventListener('updatedStorage', function (e) {
  access_token = localStorage.getItem('auth-token');
}, false);        

export default axios.create({

  baseURL: 'https://bloomcapital.soccerslot.xyz',
  
  headers: {
    "Content-type": "application/json",
    withCredentials: true,
    Authorization: `Bearer ${access_token? access_token : ''}`
  }
});
